import Gameplay from "./Gameplay"
import Header from "./components/Header"
import Playgroynd from "./components/Playground"
import QuestionModal from "./components/QuestionModal"
import RTModal from "./components/RTModal"

Header.define()
Playgroynd.define()
QuestionModal.define()
RTModal.define()

window.addEventListener('DOMContentLoaded', () => {
    const rootEl = document.getElementById('app')
    if (rootEl === null) {
        throw new Error('root element is not created')
    }

    rootEl.classList.add('root')
    const gameplay = new Gameplay(rootEl)
    gameplay.initialize()
})