import { Broadcast, EventEmitter } from "@toolcase/base"
import logging, { Logger } from "@toolcase/logging"

export type AttrFn = (name: string, old: string | null, changed: string | null) => void
export type CallbackFn = (...args: any) => void

abstract class BaseComponent extends HTMLElement {

    static readonly KEY: string = 'bbzl-base'

    static define() {
        const HTMLElement = customElements.get(this.KEY) || null
        if (HTMLElement !== null) {
            throw new Error(`base component key=(${this.KEY}) is already defined`)
        }
        customElements.define(this.KEY, this as unknown as CustomElementConstructor)
    }

    public readonly events = new Broadcast<string, CallbackFn, any>()

    protected readonly attr = new Broadcast<string, AttrFn, any>()
    private logger: Logger

    constructor() {
        super()
        this.logger = logging.getLogger(`element[name=${this.tagName.toLowerCase()}]`)
    }

    protected abstract initialize(): void | Promise<void>
    protected abstract render(): void
    protected abstract dispose(): void
    protected adapt() {}

    private async connectedCallback() {
        await this.initialize()
        this.render()
    }
    
    private disconnectedCallback() {
        this.attr.removeAllListeners()
        this.dispose()
    }
    
    private adoptedCallback() {
        this.adapt()
    }
    
    private async attributeChangedCallback(name: string, oldValue: any, newValue: any) {
        if (this.attr.listenerCount(name) === 0) {
            return this.logger.warning(`attribute change event for attr=${name} is not handled`)
        }
        this.attr['emit'](name, name, oldValue, newValue)
    }

    protected forceRender() {
        this.innerHTML = ''
        this.render()
    }

    protected emit(event: string, ...messages: any) {
        if (this.events.listenerCount(event) === 0) {
            return this.logger.warning(`event key=${event} is not handled`)
        }
        this.events['emit'](event, ...messages)
    }

}

export default BaseComponent