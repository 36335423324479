import {Broadcast as $dPrAa$Broadcast, Serializer as $dPrAa$Serializer} from "@toolcase/base";
import $dPrAa$toolcaselogging from "@toolcase/logging";




const $b764d8e58967cf88$var$serializer = new (0, $dPrAa$Serializer)("@rivalis/message");
const $b764d8e58967cf88$var$DATA_MODEL = "realtime_message";
$b764d8e58967cf88$var$serializer.define($b764d8e58967cf88$var$DATA_MODEL, [
    {
        key: "topic",
        type: "string",
        rule: "required"
    },
    {
        key: "payload",
        type: "bytes",
        rule: "required"
    }
]);
const $b764d8e58967cf88$export$c564cdbbe6da493 = (topic, payload)=>{
    return $b764d8e58967cf88$var$serializer.encode($b764d8e58967cf88$var$DATA_MODEL, {
        topic: topic,
        payload: payload
    });
};
const $b764d8e58967cf88$export$2f872c0f2117be69 = (buffer)=>{
    return $b764d8e58967cf88$var$serializer.decode($b764d8e58967cf88$var$DATA_MODEL, buffer);
};


const $18c4e7e31cfff549$var$encoder = new window.TextEncoder();
const $18c4e7e31cfff549$var$EMPTY_PAYLOAD = new Uint8Array();
/**
 * @typedef EventTypes
 * @type {string}
 */ /**
 * @callback EventListener
 * @param {Uint8Array} payload
 * @param {string} [topic]
 */ /**
 * @class
 * @augments Broadcast<EventTypes,EventListener,any?>
 */ class $18c4e7e31cfff549$var$WSClient extends (0, $dPrAa$Broadcast) {
    /** @private */ logger = (0, $dPrAa$toolcaselogging).getLogger("ws client");
    /**
     * @private
     * @type {string}
     */ baseURL = null;
    /**
     * @private
     * @type {WebSocket}
     */ ws = null;
    /**
     * 
     * @param {string} baseURL 
     */ constructor(baseURL){
        super();
        this.baseURL = baseURL;
    }
    get connected() {
        return this.ws !== null;
    }
    connect(ticket = "") {
        if (this.connected) return this.logger.warning("the client is already connected");
        if (typeof ticket !== "string") throw new Error(`ticket must be a sting, ${ticket} provided`);
        let url = new window.URL(this.baseURL);
        url.searchParams.append("ticket", ticket);
        this.ws = new window.WebSocket(url.toString());
        this.ws.onopen = this.onOpen;
        this.ws.onclose = this.onClose;
        this.ws.binaryType = "arraybuffer";
    }
    disocnnect() {
        if (!this.connected) return;
        this.ws.close();
        this.ws = null;
        this.emit("client:disconnect", $18c4e7e31cfff549$var$encoder.encode("terminated"));
    }
    /**
     * 
     * @param {string} topic 
     * @param {Uint8Array|string} [payload] 
     */ send(topic, payload = $18c4e7e31cfff549$var$EMPTY_PAYLOAD) {
        if (!this.connected) return this.logger.warning("send fail: connection is not established yet");
        if (typeof topic !== "string") throw new Error(`topic must be a string, ${topic} provided`);
        if (payload instanceof Uint8Array) return this.ws.send((0, $b764d8e58967cf88$export$c564cdbbe6da493)(topic, payload));
        if (typeof payload === "string") return this.ws.send((0, $b764d8e58967cf88$export$c564cdbbe6da493)(topic, $18c4e7e31cfff549$var$encoder.encode(payload)));
        throw new Error(`send error: invalid payload=${payload}, must be a string or Buffer`);
    }
    /** @private */ onOpen = ()=>{
        this.ws.onmessage = this.onMessage;
        this.emit("client:connect");
    };
    /**
     * @private
     * @param {MessageEvent} message 
     */ onMessage = (message)=>{
        let { topic: topic , payload: payload  } = (0, $b764d8e58967cf88$export$2f872c0f2117be69)(new Uint8Array(message.data));
        this.emit(topic, payload);
    };
    /**
     * @private
     * @param {CloseEvent} event
     */ onClose = (event)=>{
        this.emit("client:disconnect", $18c4e7e31cfff549$var$encoder.encode(event.reason));
        this.ws = null;
    };
    /**
     * @protected
     * @param {EventTypes} eventName 
     * @param  {...any} messages 
     * @returns {boolean}
     */ emit(eventName, ...messages) {
        if (this.events.listenerCount(eventName) === 0) return this.logger.warning(`event=${eventName} emitted, register listener to handle the event`);
        return super.emit(eventName, ...messages);
    }
}
var $18c4e7e31cfff549$export$2e2bcd8739ae039 = $18c4e7e31cfff549$var$WSClient;




export {$18c4e7e31cfff549$export$2e2bcd8739ae039 as WSClient};
