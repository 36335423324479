import { Apps, RTClient } from "@bbzl-multiplayer/sdk"
import Header from "./components/Header"
import Playgroynd from "./components/Playground"
import QuestionModal from "./components/QuestionModal"
import RTModal from "./components/RTModal"
import UAParser from "ua-parser-js"

class Gameplay {

    public header: Header
    public playground: Playgroynd
    public questionModal: QuestionModal
    public rtModal: RTModal

    public client: RTClient = new RTClient()
    public session: Apps.Pal

    constructor(private parent: HTMLElement) {
        this.header = document.createElement(Header.KEY) as Header
        this.playground = document.createElement(Playgroynd.KEY) as Playgroynd
        this.questionModal = document.createElement(QuestionModal.KEY) as QuestionModal
        this.rtModal = document.createElement(RTModal.KEY) as RTModal

        this.header.classList.add('container')
        this.playground.classList.add('container')

        this.questionModal.classList.add('modal')
        this.rtModal.classList.add('modal')

        this.parent.append(this.header, this.playground, this.questionModal, this.rtModal)
    }

    public initialize() {
        this.rtModal.setVisible(true)
        this.rtModal.events.on('join', this.onJoin, this)
        this.rtModal.events.on('create', this.onCreate, this)
        this.playground.events.on('item_click', this.onItemClick, this)
        this.questionModal.events.on('close', this.onQuestionClose, this)
        this.questionModal.events.on('submit', this.onQuestionSubmit, this)
    }

    private async onCreate() {
        try {
            const response = await fetch('/api/v1/rooms/create', { method: 'post' })
            const payload = await response.json()
            const { access_code } = payload.data
            return await this.onJoin(access_code)
        } catch (error) {
            console.error(error)
        }
    }

    private async onJoin(code: string) {
        console.log()
        const URL = `ws://${window.location.host}`
        this.session = await this.client.connect(URL, code, Apps.Pal) as Apps.Pal
        
        this.rtModal.setVisible(false)
        this.onStateUpdate()

        this.session.subscribe('state', this.onStateUpdate, this)
    }

    private onStateUpdate() {
        const state = this.session.get()
        this.questionModal.update({
            title: `Points: ${state.question.points}`,
            text: state.question.text,
            imageUrl: state.question.imageUrl,
            options: state.question.options,
            answer: this.isMobile() ? state.question.answer : null
        }, state.question.active)

        this.playground.update({
            questions: state.questions.count,
            eliminated: state.questions.eliminated
        })

        this.header.update({
            code: state.room.id,
            points: state.teams.points,
            team: state.teams.current
        })
    }

    private isMobile() {
        const userAgent = new UAParser(navigator.userAgent)
        const deviceType = userAgent.getDevice().type
        return deviceType === 'mobile' || deviceType === 'tablet'
        return true
    }

    private onItemClick(index: number) {
        this.session.invoke('question.select', { index })
    }

    private onQuestionClose() {
        this.session.invoke('question.select', { index: null })
    }

    private onQuestionSubmit(index: number) {
        this.session.invoke('question.answer', { optionIndex: index })
    }

}

export default Gameplay