import BaseComponent from "./BaseComponent";

abstract class BaseModal extends BaseComponent {

    private closeFlag = true

    protected abstract renderWindow(): HTMLElement

    protected render() {
        this.addEventListener('click', () => {
            if (!this.closeFlag) {
                return
            }
            this.setAttribute('visible', 'false')
            this.emit('close')
        })
        const content = document.createElement('div')
        content.classList.add('modal__content')
        this.append(content)

        const window = this.renderWindow()
        window.addEventListener('click', e => e.stopPropagation())
        content.append(window)
    }

    enableClose(flag = true) {
        this.closeFlag = flag
    }

    public setVisible(flag: boolean = true) {
        this.setAttribute('visible', flag ? 'true' : 'false')
    }

}

export default BaseModal