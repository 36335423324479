import { Models } from "@bbzl-multiplayer/base"
import App from "./App"

class Pal extends App<Models.Pal> {
    
    protected onInitialize(): void {
        
    }
    
    protected onDispose(): void {

    }

}

export default Pal