import BaseModal from "../core/BaseModal"

export type Question = {
    title: string
    text: string
    imageUrl: string
    options: string[]
    answer: string | null
}

class QuestionModal extends BaseModal {

    static readonly KEY: string = 'bbzl-question-modal'

    protected initialize() {
        
    }

    protected renderWindow() {
        const parent = document.createElement('div')
        parent.classList.add('question-modal__window')

        const titleEl = document.createElement('div')
        titleEl.classList.add('question-modal__title')
        titleEl.innerText = '[TITLE]'

        const imageEl = document.createElement('div')
        imageEl.classList.add('question-modal__image')
        const picture = document.createElement('img')
        picture.setAttribute('src', '')
        const questionText = document.createElement('h2')
        questionText.innerText = '[QUESTION]'        
        imageEl.append(picture, questionText)

        const optsEl = this.renderOpts()
        optsEl.classList.add('question-modal__opts')

        parent.append(titleEl, imageEl, optsEl)

        return parent
    }

    protected dispose() {}

    public update(data: Partial<Question>, visible: boolean | null = null) {
        if (visible !== null) {
            this.setVisible(visible)
        }
        if (data.title) {
            this.updateTitle(data.title)
        }
        if(data.imageUrl) {
            this.updateImage(data.imageUrl)
        }
        if (data.text) {
            this.updateText(data.text)
        }
        if (data.options) {
            this.updateOptions(data.options, data.answer)
        }
    }

    public updateTitle(title: string) {
        this.getTitle().innerText = title
    }

    public updateImage(src: string) {
        this.getImage().src = src
    }

    public updateText(text: string) {
        this.getText().innerText = text
    }

    public updateOptions(opts: string[], answer: string | null = null) {
        for (let [ index, element ] of this.getOptions().entries()) {
            const optionText = opts[index] || null
            if (optionText === null) {
                break
            }
            if (answer === null) {
                element.innerText = optionText
            } else {
                element.innerHTML = `${optionText} (${answer === optionText ? '<span>✓</span>' : '<span>X</span>'})`
            }
        }
    }

    private renderOpts() {
        const optsEl = document.createElement('div')
        for (let index = 0; index < 4; index++) {
            const button = document.createElement('button')
            button.addEventListener('click', () => this.handleSubmit(index))
            button.innerText = ''
            optsEl.append(button)
        }

        return optsEl
    }

    private handleSubmit(index: number) {
        this.emit('submit', index)
    }

    getTitle() {
        return this.querySelector('.question-modal__title') as HTMLElement
    }

    getImage() {
        return this.querySelector('.question-modal__image img') as HTMLImageElement
    }

    getText() {
        return this.querySelector('.question-modal__image h2') as HTMLHeadingElement
    }

    getOptions() {
        return this.querySelectorAll('.question-modal__opts button') as NodeListOf<HTMLButtonElement>
    }

}

export default QuestionModal