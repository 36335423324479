import BaseComponent from "../core/BaseComponent"

export type HeaderOptions = {
    code: string
    team: number
    points: number[] 
}

class Header extends BaseComponent {

    static readonly KEY: string = 'bbzl-header'

    static observedAttributes = [
        'code',
        'team',
        'points'
    ]

    private points = [0, 0]
    private desired = [0, 0]

    private updateIntervalId: number

    protected initialize() {
        this.attr.on('code', this.onCodeChange, this)
        this.attr.on('team', this.onTeamChange, this)
        this.attr.on('points', this.onPointsChange, this)

        this.updateIntervalId = setInterval(() => this.onUpdateTick(), 1000 / 24) as unknown as number
    }

    protected render() {
        const childs: HTMLElement[] = []
        for (let [ index, points ] of this.points.entries()) {
            const teamPointsEl = this.renderTeamPoints(index + 1, points)
            teamPointsEl.classList.add('header__team-points')
            childs.push(teamPointsEl)
        }

        this.innerHTML = ''
        const accessCodeEl = this.renderAccessCode()
        childs.forEach((element, index) => {
            const canRenderAC = Math.floor(childs.length / 2) === index
            if (canRenderAC) {
                this.append(accessCodeEl)
            }
            this.append(element)
        })
    }

    protected dispose() {
        clearInterval(this.updateIntervalId)
    }

    public update(data: Partial<HeaderOptions> = {}) {
        if (typeof data.code !== 'undefined') {
            this.setAttribute('code', data.code)
        }
        if (Array.isArray(data.points)) {
            this.setAttribute('points', data.points.join(','))
        }
        if (typeof data.team !== 'undefined') {
            this.setAttribute('team', data.team.toString())
        }
    }

    private renderTeamPoints(index: number, points: number) {
        const teamPointsEl = document.createElement('div')
        const label = document.createElement('div')
        label.classList.add('header__team-points__label')
        label.innerText = `Team ${index}`
        
        const value = document.createElement('div')
        value.classList.add('header__team-points__value')
        value.innerText = points.toString()

        teamPointsEl.append(label, value)
        return teamPointsEl
    }

    private renderAccessCode() {
        const accessCodeEl = document.createElement('div')
        accessCodeEl.classList.add('header__access-code')
        
        const labelEl = document.createElement('div')
        labelEl.classList.add('header__access-code__label')
        labelEl.innerText = 'Access code'

        const valueEl = document.createElement('div')
        valueEl.classList.add('header__access-code__value')
        valueEl.innerText = this.getAttribute('code') || ''

        accessCodeEl.append(labelEl, valueEl)
        return accessCodeEl
    }

    private onUpdateTick() {
        const teamPointList = this.querySelectorAll('.header__team-points')
        for (let [ index, element ] of teamPointList.entries()) {
            const valueEl = element.querySelector('.header__team-points__value') as HTMLElement
            const previous = parseInt(valueEl.innerText, 10)
            const desired = this.desired[index]
            if (previous === desired) {
                continue
            }
            const next = previous < desired ? previous + 1 : previous -1
            valueEl.innerText = next.toString()
        }
        this.dispatchEvent(new Event('tick', { cancelable: true }))
    }

    private onCodeChange(name: string, old: string | null, changed: string | null) {
        const accessCodeEl = this.querySelector('.header__access-code__value') as HTMLElement
        accessCodeEl.innerHTML = changed || ''
    }

    private onTeamChange(name: string, old: string | null, changed: string | null) {
        const teamPointList = this.querySelectorAll('.header__team-points')
        teamPointList.forEach(element => {
            const label = element.querySelector('.header__team-points__label')
            if (label === null) {
                return
            }
            label.classList.remove('active')
        })
        const currentIndex = parseInt(changed || '', 10)
        const element = teamPointList[currentIndex] || null
        if (element === null) {
            return
        }

        const labelEl = element.querySelector('.header__team-points__label')
        if (labelEl === null) {
            return
        }
        labelEl.classList.add('active')
    }

    private onPointsChange(name: string, old: string | null, changed: string | null) {
        const values: number[] = []
        if (changed === null) {
            this.points.forEach(() => values.push(0))
        } else {
            changed.split(',').forEach(textValue => {
                const value = parseInt(textValue, 10)
                values.push(value)
            })
        }
        if (values.length === this.points.length) {
            this.desired = values
        }
    }

}

export default Header