import { WSClient } from "@rivalis/browser"
import { Models } from "@bbzl-multiplayer/base"
import App from "./apps/App"
import logging from "@toolcase/logging"

interface AppConstructor<T extends Models.Base> {
    new (client: WSClient, initialPayload: any): App<T>
}

class RTClient {
    private client: WSClient | null = null
    private logger = logging.getLogger('RTClient')
    private decoder = new TextDecoder()
    private current: App<Models.Base> = null

    public connect<T extends Models.Base>(url: string, ticket: string, connectionClass: AppConstructor<T>) {
        if (this.client !== null) {
            throw new Error('already connected')
        }
        this.client = new WSClient(url)
        this.client.connect(ticket)
        return new Promise<App<T>>((resolve, reject) => {
            this.client.once('client:disconnect', error => {
                this.client = null
                reject(this.decoder.decode(error))
            })
            this.client.once('client:connect', () => {
                this.logger.verbose(`connected to server url=(${url})`)
                this.client.once('state.sync', (payload: Uint8Array) => {
                    const adapter = new connectionClass(this.client, payload)
                    this.current = adapter
                    resolve(adapter)
                })
            })
        })
    }

    public disconnect() {
        if (this.client === null) {
            throw new Error('is not connected')
        }
        this.client.removeAllListeners('client:disconnect')
        this.current['dispose']()
        this.client.disocnnect()
        this.client = null
    }

    get app() {
        return this.current
    }
}

export default RTClient