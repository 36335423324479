import { Models } from "@bbzl-multiplayer/base"
import { State } from "@toolcase/base"
import { WSClient } from "@rivalis/browser"

abstract class App<T extends Models.Base> {

    static readonly UPDATE_INTERVAL = 500

    private state: State<T>
    private buffer: State<T> = new State({})
    private lastUpdate: number = 0
    private updateTimeoutId: number = null
    private decoder = new TextDecoder()
    private encoder = new TextEncoder()

    constructor(protected client: WSClient, initialPayload: Uint8Array) {
        this.state = new State<T>(this.decode(initialPayload))
        this.initialize()
    }

    protected abstract onInitialize(): void
    protected abstract onDispose(): void

    public get() {
        return this.state.get() as T
    }

    public set(data: Partial<T>) {
        this.buffer.set(data)
        this.sendUpdate()
    }

    public subscribe(event: string, callback: (value: any) => void, context?: any) {
        this.state.on(event, callback, context)
        return this
    }

    public unsubscribe(event: string, callback: (value: any) => void, context?: any) {
        this.state.off(event, callback, context)
        return this
    }

    public invoke(key: string, data: any = {}) {
        const payload = this.encode(data)
        this.client.send(key, payload)
    }

    protected encode(data: any) {
        const stringified = JSON.stringify(data)
        const payload = this.encoder.encode(stringified)
        return payload
    }

    protected decode(payload: Uint8Array) {
        const stringified = this.decoder.decode(payload)
        const data = JSON.parse(stringified)
        return data
    }

    private sendUpdate() {
        const canProceed = this.lastUpdate + App.UPDATE_INTERVAL <= Date.now()
        if (canProceed) {
            clearTimeout(this.updateTimeoutId)
            this.updateTimeoutId = null
            return this.sendBuffer()
        }
        if (this.updateTimeoutId !== null) {
            return
        }
        const diff = Date.now() - (this.lastUpdate + App.UPDATE_INTERVAL)
        this.updateTimeoutId = setTimeout(() => this.sendUpdate(), diff) as unknown as number
    }

    private sendBuffer() {
        this.lastUpdate = Date.now()
        const payload = this.encode(this.buffer.get())
        this.client.send('state.sync', payload)
        this.buffer.empty()
    }

    private initialize() {
        this.client.on('state.sync', this.onStateUpdate, this)
        this.onInitialize()
    }

    private onStateUpdate(payload: Uint8Array) {
        const delta = this.decode(payload)
        this.state.set(delta)
    }

    private dispose() {
        clearTimeout(this.updateTimeoutId)
        this.state.removeAllListeners()
        this.buffer = null
        this.decoder = null
        this.state = null
        this.onDispose()
    }


}

export default App