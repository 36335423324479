import BaseComponent from "../core/BaseComponent"

export type GridOptions = {
    questions: number,
    eliminated: number[]
}

class Playgroynd extends BaseComponent {

    static readonly KEY: string = 'bbzl-playground'

    static observedAttributes = [
        'questions',
        'eliminated'
    ]

    get questions() {
        return parseInt(this.getAttribute('questions') || '0', 10)
    }

    protected initialize() {
        this.attr.on('questions', () => this.forceRender())
        this.attr.on('eliminated', () => this.markEliminated())
    }

    protected render() {
        const rows = Math.floor(Math.sqrt(this.questions)) || 1
        const cols = Math.ceil(this.questions / rows)
        
        const items: HTMLElement[] = []
        for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
            for (let colIndex = 0; colIndex < cols; colIndex++) {
                const index = rowIndex * cols + colIndex
                if (index >= this.questions) {
                    break
                }
                const label = (index + 1).toString()
                const item = this.renderItem(rows, cols, label)
                item.addEventListener('click', () => {
                    this.emit('item_click', index)
                })
                items.push(item)
            }
        }
        this.append(...items)
    }

    protected dispose() {}

    public update(data: Partial<GridOptions>) {
        if (data.questions) {
            this.setAttribute('questions', data.questions.toString())
        }
        if (Array.isArray(data.eliminated)) {
            this.setAttribute('eliminated', data.eliminated.join(','))
        }
    }

    private renderItem(rows: number, cols: number, label: string) {
        const parent = document.createElement('div')
        parent.classList.add('playground__item')

        const offset = (cols * 5)
        parent.style.width = `calc(${100 / cols}% - ${offset}px)`

        parent.style.height = `calc((85vh - 150px) / ${rows})`
        
        const labelEl = document.createElement('div')
        labelEl.classList.add('playground__item__label')
        labelEl.innerText = label
        parent.append(labelEl)
        return parent
    }

    private markEliminated() {
        const eliminatedList = (this.getAttribute('eliminated') || '').split(',').filter(value => value !== '').map(value => {
            return parseInt(value, 10)
        })

        const nodes = this.querySelectorAll('.playground__item')
        nodes.forEach(node => node.classList.remove('disabled'))
        for (let eliminatedIndex of eliminatedList) {
            const node = nodes.item(eliminatedIndex) || null
            node.classList.add('disabled')
        }
    }

}

export default Playgroynd