import BaseModal from "../core/BaseModal"

class RTModal extends BaseModal {
    
    static readonly KEY: string = 'bbzl-rt-modal'
    
    protected initialize() {
        this.enableClose(false)
    }

    protected renderWindow() {
        const parent = document.createElement('div')
        parent.classList.add('rt-modal__window')

        const groupJoin = document.createElement('div')
        groupJoin.classList.add('rt-modal__group', 'rt-modal__join')
        const joinHeading = document.createElement('h2')
        joinHeading.innerText = 'JOIN ROOM'
        const joinInput = document.createElement('input')
        joinInput.placeholder = 'Enter access code...'
        const joinButton = document.createElement('button')
        joinButton.innerText = 'Join'
        joinButton.addEventListener('click', () => {
            this.emit('join', joinInput.value)
        })
        const joinHelp = document.createElement('p')
        joinHelp.innerText = '[HELP TEXT HERE]'
        groupJoin.append(joinHeading, joinInput, joinButton, joinHelp)

        const groupCreate = document.createElement('div')
        groupCreate.classList.add('rt-modal__group', 'rt-modal__create')
        const createHeading = document.createElement('h2')
        createHeading.innerText = 'CREATE ROOM'
        const createButton = document.createElement('button')
        createButton.innerText = 'Create'
        createButton.addEventListener('click', () => this.emit('create'))
        const createHelp = document.createElement('p')
        createHelp.innerText = '[HELP TEXT HERE]'
        groupCreate.append(createHeading, createButton, createHelp)

        parent.append(groupJoin, groupCreate)
        return parent
    }

    protected dispose() {

    }

}

export default RTModal